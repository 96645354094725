<template>
  <div>
    <div class="approvalAnswers">
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-container class="bg-light" :fluid="$screenSize > 600 ? false : true" v-else>
        <b-row>
          <b-col md="12">
            <b-modal id="finishApproval" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
              hide-header centered size="md">
              <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
                Are you sure you want to submit the form, once submitted it
                cannot be changed ?
              </h6>
              <div class="buttons">
                <b-button size="sm" variant="primary" @click="prepareAnswersForApi(true)">Yes</b-button>
                <b-button size="sm" variant="danger" class="ml-2" @click="$bvModal.hide('finishApproval')">No</b-button>
              </div>
            </b-modal>
            <br />
            <!-- <p>USERID: {{ userId }}</p>
            <p>{{ startApproval }}</p> -->
            <div id="studentSubmittedForm" v-if="studentAnswerSheet.length != 0">
              <div>

                <h5 class="text-info mb-0" style="font-size: 20px !important; font-weight: 600; text-align: center">
                  {{ startApproval.onlinereg_with_questions.institution_name }}
                </h5>
                <p class="text-info mb-0" style="font-size: 12px !important;  text-align: center">
                  {{ startApproval.onlinereg_with_questions.institution_name_2 }} <br>
                  {{ startApproval.onlinereg_with_questions.institution_name_3 }}
                </p>
              </div>
              <div class="d-flex justify-content-between">
                <p></p>
                <h6 class="text-info" style="font-size: 16px; font-weight: 600; text-align: center">
                  {{
                      startApproval.onlinereg_with_questions.name
                  }}
                </h6>
                <b-button variant="danger" v-if="applicationSubmittedTime != null"
                  class="mb-2 text-right font-weight-bolder" size="sm" @click="downloadApprovalSubmittedForm()"
                  :disabled="downloadPdf.disabled">{{ downloadPdf.text }}</b-button>
              </div>

              <h6 class="alert alert-success text-center" style="font-size: 16px; font-weight: 600"
                v-if="applicationSubmittedTime != null">
                Form Submitted
              </h6>

              <b-form class="border-0" @submit.prevent="
                applicationSubmittedTime == null
                  ? showWarningBeforeSubmission()
                  : null
              ">
                <div class="studentSheet">
                  <div class="saveChanges mb-2 w-100">
                    <b-button variant="success" size="sm" pill class="font-weight-bolder"
                      :disabled="saveChanges.disabled" @click="prepareAnswersForApi(false)"
                      v-if="applicationSubmittedTime == null">{{ saveChanges.text }}</b-button>
                  </div>

                  <br>
                  <div>
                    <p class="text-info mb-0" style="font-size: 12px !important; ">
                      {{ startApproval.onlinereg_with_questions.form_guideline }}
                    </p>
                  </div>
                  <br>
                  <div class="sheet border border-info rounded p-3 mt-2 bg-white"
                    v-for="(sheet, sheetIndex) in studentAnswerSheet" :key="sheetIndex"
                    style="font-size: 14px; font-weight: 600">
                    <b-overlay :show="loadAnswer" spinner-variant="primary" spinner-type="grow" spinner-small
                      rounded="md">
                      <div class="questionAndNo mb-2">
                        <p>
                          {{ sheet.question_no }}:<span class="ml-2">{{
                              sheet.name
                          }} <span v-if="sheet.input_attributes[0].answerMandatory"
                              style="color: red; display: inline;">*</span></span>
                        </p>
                      </div>
                      <div class="attachment mb-2" v-if="sheet.allow_attachment">
                        <div class="text-right mt-3">
                          <span class="text-info btn p-0 m-0" @click="newFileInputMask(sheet.id)"
                            v-if="applicationSubmittedTime == null"><i class="fas fa-paperclip fa-2x"></i></span>
                          <b-form-file class="text-info btn p-0 m-0" :ref="'newFileInput' + sheet.id" type="file"
                            @change="
                              newFileInput($event.target.files, sheet.id)
                            " style="display: none"></b-form-file>
                          <div>
                            <div class="text-left" style="overflow: hidden">
                              <div class="d-flex flex-column">
                                <span class="text-dark ml-3" v-for="(file, fileIndex) in sheet.files" :key="fileIndex">
                                  <a :href="
                                    userAttachments +
                                    file.file_path +
                                    file.file_name
                                  " target="_blank" class="text-decoration-none"
                                    style="font-size: 14px; font-weight: 600">{{ file.file_name }}</a>
                                  <span v-if="applicationSubmittedTime == null" class="btn p-0 m-0 ml-2 text-danger"
                                    @click="
                                      deleteAnswerAttachment(
                                        sheetIndex,
                                        file.id,
                                        fileIndex
                                      )
                                    "><i class="fas fa-trash"></i></span>
                                </span>
                                <span class="input-error-message text-right" v-if="
                                  !$v.studentAnswerSheet.$each[sheetIndex]
                                    .files.required &&
                                  $v.studentAnswerSheet.$each[sheetIndex]
                                    .files.$dirty
                                ">
                                  Attachment is mandatory!
                                </span>
                              </div>
                            </div>

                            <div v-if="newQuestionsAnswerAttachments[sheet.id]">
                              <span class="text-dark ml-3" v-for="(newFile,
                              newFileIndex) in newQuestionsAnswerAttachments[
    sheet.id
  ]" :key="newFileIndex">
                                <b-progress :value="uploadPercentage[sheet.id].value" :max="100" show-progress
                                  animated></b-progress>
                                <span>
                                  {{ newFile }}

                                  <br />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="answerField mb-2" v-if="
                        sheet.input_attributes[0].shortAnswer ||
                        sheet.input_attributes[0].paragraph
                      ">
                        <b-form-input placeholder="Enter Answer" v-model="sheet.studentAnswer"
                          v-if="applicationSubmittedTime == null" :class="{
                            'form-group-error':
                              $v.studentAnswerSheet.$each[sheetIndex]
                                .studentAnswer.$error,
                          }"></b-form-input>
                        <p v-else>{{ sheet.studentAnswer }}</p>
                      </div>

                      <div class="multichoice mb-2" v-if="sheet.input_attributes[0].multiChoice">
                        <b-form-checkbox-group size="sm" v-model="sheet.multichoiceAns"
                          v-if="applicationSubmittedTime == null" :options="sheet.choices" :class="{
                            'form-group-error':
                              $v.studentAnswerSheet.$each[sheetIndex]
                                .studentAnswer.$error,
                          }" @change="storeAnswerFromCheckboxes(sheetIndex)">
                        </b-form-checkbox-group>
                        <p v-else>{{ sheet.multichoiceAns }}</p>
                      </div>
                      <div class="checkboxes mb-2" v-if="sheet.input_attributes[0].checkboxes">
                        <b-form-radio-group v-model="sheet.studentAnswer" :options="sheet.choices"
                          v-if="applicationSubmittedTime == null" :checked="sheet.studentAnswer ? true : false" :class="{
                            'form-group-error':
                              $v.studentAnswerSheet.$each[sheetIndex]
                                .studentAnswer.$error,
                          }">
                        </b-form-radio-group>
                        <p v-else>{{ sheet.studentAnswer }}</p>
                      </div>

                      <div class="dropdown mb-2" v-if="sheet.input_attributes[0].dropdown">
                        <b-form-select v-model="sheet.studentAnswer" :options="sheet.choices"
                          v-if="applicationSubmittedTime == null" :class="{
                            'form-group-error':
                              $v.studentAnswerSheet.$each[sheetIndex]
                                .studentAnswer.$error,
                          }"></b-form-select>
                        <p v-else>{{ sheet.studentAnswer }}</p>
                      </div>
                      <span class="input-error-message" v-if="
                        !$v.studentAnswerSheet.$each[sheetIndex].studentAnswer
                          .required &&
                        $v.studentAnswerSheet.$each[sheetIndex]
                          .studentAnswer.$dirty
                      ">
                        Answer is mandatory!
                      </span>
                    </b-overlay>
                  </div>
                </div>
                <br />
                <b-form-group v-if="applicationSubmittedTime == null">
                  <div class="text-center">
                    <b-button variant="primary" size="md" type="submit" v-if="studentAnswerSheet.length != 0"
                      :disabled="responseBtn.disabled">{{ responseBtn.text }}</b-button>
                  </div>
                </b-form-group>
                <br /><br />
              </b-form>
            </div>
            <div v-else>
              <br />
              <div class="alert alert-danger text-center font-weight-bolder">
                No questions found! Check Back Later!
                <span>Redirecting back to institute.</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
//{input_type:"", required: booleaN, }
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  props: ["registrationId", "publicId"],
  async created() {
    // await this.getRegistrationDetails();
    await this.startSurveyApproval();
    this.userAttachments = await this.$store.getters.getAPIKey.userAttachments;
  },
  data() {
    return {
      fetchData: false,
      userAttachments: null,
      loadAnswer: false,
      registrationDetails: {},
      startApproval: {},
      studentAnswerSheet: [],
      newQuestionsAnswerAttachments: {},
      uploadPercentage: {},
      responseBtn: {
        text: "Submit",
        disabled: false,
      },
      saveChanges: {
        text: "Save Changes",
        disabled: false,
      },
      questions: [],
      answers: [],
      applicationSubmittedTime: null,
      printSheet: false,
      downloadPdf: {
        text: "Download",
        disabled: false,
      },
      userId: null,
    };
  },
  validations: {
    studentAnswerSheet: {
      required,
      $each: {
        files: {
          required: requiredIf(function (mandatory) {
            if (
              mandatory.input_attributes[0].answerMandatory &&
              mandatory.allow_attachment
            ) {
              return true;
            }
          }),
        },
        studentAnswer: {
          required: requiredIf(function (mandatory) {
            if (
              mandatory.input_attributes[0].answerMandatory &&
              (mandatory.input_attributes[0].shortAnswer ||
                mandatory.input_attributes[0].paragraph ||
                mandatory.input_attributes[0].multiChoice ||
                mandatory.input_attributes[0].checkboxes ||
                mandatory.input_attributes[0].dropdown)
            )
              return true;
          }),
        },
      },
    },
  },
  methods: {
    async downloadApprovalSubmittedForm() {
      this.downloadPdf = {
        text: "Downloading....",
        disabled: true,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${this.userId}/report/pdfexport`;
      await this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.startApproval.onlinereg_with_questions.name + ".pdf"
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadPdf = {
            text: "Download",
            disabled: false,
          };
          response;
        })
        .catch((error) => {
          this.downloadPdf = {
            text: "Download",
            disabled: false,
          };
          error;
        });
    },
    // async printStudentAnswerSheet() {
    //   window.print();
    // },
    storeAnswerFromCheckboxes(sheetIndex) {
      let multichoicesOptions = this.studentAnswerSheet[sheetIndex]
        .multichoiceAns;
      this.studentAnswerSheet[sheetIndex].studentAnswer = null;
      this.studentAnswerSheet[
        sheetIndex
      ].studentAnswer = multichoicesOptions.join();
    },
    async startSurveyApproval() {
      this.fetchData = true;
      const publicId = await this.$route.params.public_id;

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregistrations/publicform`;
      this.$axios
        .post(url, { public_id: publicId })
        .then((response) => {
          this.startApproval = response.data;
          if (this.startApproval.onlinereg_with_questions.hidden) {
            this.responseBtn.disabled = true;
            this.saveChanges.disabled = true;
            alert('Online form you are trying to access is currently disabled or the last date for submissions has expired');

          } else {
            this.questions = this.startApproval.onlinereg_with_questions.questions;
            this.answers = this.startApproval.application_with_answers.answers;
            this.userId = this.startApproval.application_with_answers.id;
            this.applicationSubmittedTime = this.startApproval.application_with_answers.end_at;
            this.prepareAnswerSheet();
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async prepareAnswerSheet() {
      await this.getApprovalQuestions();
      await this.getApprovalAnswers();

      // this.fetchData = false;
    },
    async showWarningBeforeSubmission() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$bvModal.show("finishApproval");
      }
    },
    async prepareAnswersForApi(status) {
      this.$bvModal.hide("finishApproval");
      this.responseBtn.text = "Please wait";
      this.responseBtn.disabled = true;
      this.saveChanges.text = "Saving...";
      this.saveChanges.disabled = true;
      this.loadAnswer = true;

      let answersPostData = await {
        onlinereganswers: [],
        application_id: this.startApproval.application_with_answers.id,
        final_submission: !status && status != undefined ? false : true,
      };

      await this.studentAnswerSheet.forEach((ans) => {
        answersPostData.onlinereganswers.push({
          question_id: ans.id,
          answer:
            ans.multichoiceAns.length == 0
              ? ans.studentAnswer
              : ans.multichoiceAns.join(),
          attachment: "",
        });
      });

      let storeAnswer = await this.storeAnswersWithApi(answersPostData);
      if (storeAnswer) {
        await this.$toast.success(
          !status ? "Changes Saved" : "Response Submitted.",
          {
            position: "top",
            duration: 3000,
          }
        );
        this.clearResponse(!status && status != undefined ? false : true);
      }
      if (!storeAnswer) {
        this.$toast.error("Something went wrong!, Please try again", {
          position: "top",
          duration: 3000,
        });
        return this.clearResponse(false);
      }
    },
    async storeAnswersWithApi(answersPostData) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlinereganswers/storemany`;
      await this.$axios
        .post(url, answersPostData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    async uploadAttachment(file, questionId) {
      let formData = await new FormData();

      // let attachmentData = await {
      //   question_id: questionId,
      //   answer: null,
      //   application_id: this.startApproval.application_with_answers.id,
      //   attachment: file,
      // };
      await formData.append("question_id", questionId);
      await formData.append("answer", null);
      await formData.append(
        "application_id",
        this.startApproval.application_with_answers.id
      );
      await formData.append("attachment", file);
      // await formData.append("file", file);

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlinereganswers/attachmentanswer`;
      await this.$axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage[questionId].value = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          response;
        })
        .catch((error) => {
          error;
        });
      // file;
      // this.uploadPercentage[questionId]["value"] = await 50;
    },
    async deleteAnswerAttachment(sheetIndex, fileId, fileIndex) {
      this.loadAnswer = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlinereganswers/attachments/${fileId}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.studentAnswerSheet[sheetIndex].files.splice(fileIndex, 1);
          this.$toast.success("Attachment Deleted.", {
            position: "top",
            duration: 3000,
          });
          this.loadAnswer = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something Went wrong, Please try again!.", {
            position: "top",
            duration: 3000,
          });
          this.loadAnswer = false;
          error;
        });
    },
    getRegistrationDetails() {
      this.fetchData = true;
      const surveyApprovalId = this.$route.params.approval_id;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals + `/${surveyApprovalId}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.registrationDetails = {
            id: response.data.id,
            name: response.data.name,
            summary: response.data.summary,
            academic_year: response.data.academic_year,
            start_at: new Date(
              moment(
                moment
                  .utc(response.data.start_at, "YYYY-MM-DD HH:mm:ss")
                  .toDate()
              ).format()
            ),
            end_at: new Date(
              moment(
                moment.utc(response.data.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ).format()
            ),
            grouping_tag: response.data.grouping_tag,
            comment_feedback:
              response.data.comment_feedback == 1 ? true : false,
            hidden: response.data.hidden == 1 ? true : false,
            participants_only:
              response.data.participants_only == 1 ? true : false,
            is_anonymous: response.data.is_anonymous == 1 ? true : false,
          };

          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async getApprovalQuestions() {
      this.studentAnswerSheet = [];
      if (this.questions.length != 0) {
        this.questions.forEach((ques) => {
          let choices = ques.input_attributes[0].dropdown
            ? [{ value: null, text: "Select One" }]
            : [];
          if (ques.answers.length != 0) {
            ques.answers.forEach((choice) => {
              choices.push({ value: choice, text: choice });
            });
          }

          this.studentAnswerSheet.push({
            id: ques.id,
            question_no: ques.question_no,
            name: ques.name,
            summary: ques.summary,
            choices: choices,
            allow_attachment: ques.allow_attachment == 1 ? true : false,
            input_attributes: ques.input_attributes,
            studentAnswer: null,
            onlineregistration_id: ques.onlineregistration_id,
            grouping_tag: ques.grouping_tag,
            multichoiceAns: [],
            files: [],
            disabled: true,
          });
        });

        this.fetchData = false;
      } else {
        // if (this.studentAnswerSheet.length == 0) {
        //   setTimeout(() => {
        //     this.$router.push({ name: "institute" });
        //   }, 3000);
        // }
        this.fetchData = false;
      }
    },
    async getApprovalAnswers() {
      await this.studentAnswerSheet.forEach((ques) => {
        let questionId = ques.id;
        this.answers.forEach((ans) => {
          if (questionId == ans.question_id) {
            ques.studentAnswer = ans.answer;
            ques.files = ans.files;
            ques.multichoiceAns =
              ques.input_attributes[0].multiChoice && ans.answer != null
                ? ans.answer.split(",")
                : [];
          }
        });
      });
      // this.getAnswer();
      this.fetchData = false;
    },
    async getAnswer(questionId) {
      this.fetchData = true;
      const questionIndex = await this.studentAnswerSheet.findIndex((ques) => {
        return ques.id == questionId;
      });

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${this.startApproval.application_with_answers.id}/onlinereganswers`;
      await this.$axios
        .get(url)
        .then((response) => {
          const answers = response.data;
          const answerIndex = answers.findIndex((ans) => {
            return ans.question_id == questionId;
          });

          this.studentAnswerSheet[questionIndex].files =
            answers[answerIndex].files;

          this.fetchData = false;
          this.$forceUpdate();
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    }, //----------------------------------
    async newFileInput(file, questionId) {
      this.$set(this.newQuestionsAnswerAttachments, questionId, []);
      this.newQuestionsAnswerAttachments[questionId].push(file.name);

      this.$set(this.uploadPercentage, questionId, {});
      this.$set(this.uploadPercentage[questionId], "value", 0);

      let index = this.newQuestionsAnswerAttachments[questionId].indexOf(
        file.name
      );

      await this.uploadAttachment(file[0], questionId);

      await this.newQuestionsAnswerAttachments[questionId].splice(index, 1);

      this.getAnswer(questionId);
      // this.startSurveyApproval();
    },
    newFileInputMask(questionId) {
      let elementReference = `${"newFileInput" + questionId}`;
      this.$refs[elementReference][0].$refs.input.click();
    },
    clearResponse(status) {
      this.responseBtn.text = "Submit";
      this.saveChanges.text = "Save Changes";
      setTimeout(() => {
        if (status) {
          this.startSurveyApproval();
        }
        this.loadAnswer = false;
        this.responseBtn.disabled = false;
        this.saveChanges.disabeld = false;
      }, 3000);
    },
  },
};
</script>

<style >
.approvalAnswers form .studentSheet p {
  font-size: 16px;
  font-weight: 600;
}
</style>
