<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <div v-if="applicationForm">
        <PublicApprovalForm @formSubmitted="formSubmitted" :registrationId="registrationId" :publicId="publicId" />
      </div>
      <!--Existing Account-->
      <div class="registerUnapprovedUser bg-light" v-else>
        <div class="register rounded existingAccount d-flex flex-column justify-content-between align-items-center"
          v-if="accountExist && !otpVerificationScreen">
          <p class="text-info mb-0" style="font-size: 20px; font-weight: 600; text-align: center">
            {{ approval.name }}
          </p>
          <div class="text-center">
            <b-button variant="info" size="sm" @click="proceedAsExistingUser()">Proceed as {{ existingAccount.email
            }}</b-button>
            <p class="m-0 p-0 d-flex align-items-baseline justify-content-center"
              style="font-size: 12px; font-weight: 600">
              Not your account!
              <span style="font-size: 12px; font-weight: 600" class="btn p-0 m-0 text-danger"
                @click="logout()">Logout</span>
            </p>
          </div>
          <p class="text-info" style="font-size: 20px; font-weight: 600">ezygo.app</p>
        </div>
        <!--End-->

        <!--Account Doesnt Exist-->
        <div class="register rounded bg-white d-flex flex-column justify-content-between"
          v-if="!accountExist && !otpVerificationScreen">
          <div>
            <h5 class="text-info mb-0" style="font-size: 19px !important; font-weight: 600; text-align: center">
              {{ approval.institution_name }}
            </h5>
            <p class="text-info mb-0" style="font-size: 12px !important; text-align: center">
              {{ approval.institution_name_2 }} <br> {{ approval.institution_name_3 }}
            </p>
            <p class="text-info mb-0" style="font-size: 14px !important; font-weight: 600; text-align: center">
              {{ approval.name }}
            </p>
            <p class="m-0 p-0 text-center text-danger" style="font-size: 10px; font-weight: 600">
              Please enter the details to proceed
            </p>
          </div>
          <b-form @submit.prevent="prepareUserRegistration()" class="border-0">
            <b-form-group>
              <b-form-input v-model="register.first_name" placeholder="Enter Name..."></b-form-input>
              <span class="input-error-message"
                v-if="!$v.register.first_name.required && $v.register.first_name.$dirty">Name is mandatory</span>
              <span class="input-error-message"
                v-if="!$v.register.first_name.minLength && $v.register.first_name.$dirty">Name should be atleast 5
                letters</span>
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="register.email" placeholder="Enter Email..."></b-form-input>
              <span class="input-error-message" v-if="!$v.register.email.required && $v.register.email.$dirty">Email is
                mandatory!</span>
              <span class="input-error-message" v-if="!$v.register.email.email && $v.register.email.$dirty">Please enter
                a valid email address!</span>
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="register.mobile" placeholder="Enter 10 Digit Mobile Number"></b-form-input>
              <span class="input-error-message" v-if="!$v.register.mobile.required && $v.register.mobile.$dirty">Mobile
                number is mandatory!</span>
              <span class="input-error-message" v-if="!$v.register.mobile.minLength && $v.register.mobile.$dirty">Please
                enter a valid 10 digit mobile number!</span>
              <span class="input-error-message" v-if="!$v.register.mobile.maxLength && $v.register.mobile.$dirty">Please
                enter a valid 10 digit mobile number!</span>
            </b-form-group>
            <b-form-group>
              <b-button size="sm" variant="primary" type="submit" :disabled="registerBtn.disabled">{{ registerBtn.text
              }}</b-button>
            </b-form-group>
          </b-form>
          <p class="text-right text-info" style="font-size: 20px; font-weight: 600">
            ezygo.app
          </p>
        </div>
        <!--End-->

        <!--OTP Verification Screen-->
        <div class="register d-flex flex-column justify-content-between enterOtp" v-if="otpVerificationScreen">
          <p class="text-info" style="font-size: 20px; font-weight: 600; text-align: center">
            {{ approval.name }}
          </p>
          <div>
            <b-overlay :show="loadOtp" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
              <b-form @submit.prevent="prepareOtpVerification()" class="border-0">
                <p v-if="existingAccount.email" class="text-info" style="font-size: 12px; font-weight: 600">
                  Proceeding as {{ existingAccount.email }}
                </p>
                <b-form-group>
                  <b-form-input placeholder="Enter OTP" v-model="otp"></b-form-input>
                  <span class="input-error-message" v-if="!$v.otp.required && $v.otp.$dirty">OTP is required!</span>
                  <span class="input-error-message" v-if="!$v.otp.numeric && $v.otp.$dirty">Please enter a valid
                    OTP!</span>
                </b-form-group>
                <b-form-group>
                  <b-button size="sm" variant="primary" type="submit" :disabled="otpBtn.disabled">{{ otpBtn.text
                  }}</b-button>
                </b-form-group>
              </b-form>

              <vue-countdown v-if="requestOtpCountdown" :time="120000" v-slot="{ minutes, seconds }"
                @end="requestOtpCountdown = false">
                <h5 style="font-size: 12px; font-weight: 600; text-align: center">
                  Request another OTP after
                  <b>
                    <span class="text-info"></span>
                    {{ minutes }} min, {{ seconds }} sec
                  </b>
                </h5>
              </vue-countdown>
              <p class="mt-2 d-flex justify-content-center align-items-baseline"
                style="font-size: 12px; font-weight: 600" v-else>
                Request Another OTP ?
                <span style="font-size: 12px; font-weight: 600" class="btn p-0 m-0 text-info" @click="resendOtp(true)">
                  Click Here</span>
              </p>
            </b-overlay>
          </div>

          <p class="text-right text-info" style="font-size: 20px; font-weight: 600">
            ezygo.app
          </p>
        </div>
      </div>
      <!--End-->
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
  requiredIf,
} from "vuelidate/lib/validators";
import PublicApprovalForm from "./PublicApprovalForm.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  async created() {
    this.fetchData = true;
    await this.getApprovalInfo();
    let checkUserExist = await localStorage.getItem("publicUrlUser");

    if (checkUserExist) {
      let userDataObject = JSON.parse(checkUserExist);
      this.accountExist = true;
      this.$set(this.existingAccount, "email", userDataObject.email);
    }
    this.fetchData = false;
  },
  components: {
    PublicApprovalForm,
    VueCountdown,
  },
  data() {
    return {
      fetchData: false,
      loadOtp: false,
      register: {},
      registerBtn: {
        text: "Submit",
        disabled: false,
      },
      publicId: null,
      registrationId: null,
      otp: "",
      otpBtn: {
        text: "Verify Otp",
        disabled: false,
      },
      accountExist: false,
      existingAccount: {},
      otpVerificationScreen: false,
      applicationForm: false,
      requestOtpCountdown: false,
      approval: {},
    };
  },
  validations: {
    required,
    otp: {
      required: requiredIf(function () {
        if (this.otpVerificationScreen) {
          return true;
        }
      }),
      numeric,
    },
    register: {
      first_name: {
        required: requiredIf(function () {
          if (!this.otpVerificationScreen) {
            return true;
          }
        }),
        minLength: minLength(5),
      },
      email: {
        required: requiredIf(function () {
          if (!this.otpVerificationScreen) {
            return true;
          }
        }),
        email,
      },
      mobile: {
        required: requiredIf(function () {
          if (!this.otpVerificationScreen) {
            return true;
          }
        }),
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  methods: {
    async getApprovalInfo() {
      const publicId = this.$route.params.public_id;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregistrations/publicform/${publicId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.approval = response.data;
          this.publicId = publicId;
          this.registrationId = this.approval.id;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    proceedAsExistingUser() {
      this.resendOtp(false);
      this.accountExist = false;
      this.otpVerificationScreen = true;
    },
    async formSubmitted() {
      this.fetchData = await true;
      let checkUserExist = await localStorage.getItem("publicUrlUser");

      if (checkUserExist) {
        let userDataObject = JSON.parse(checkUserExist);
        this.accountExist = true;
        this.$set(this.existingAccount, "email", userDataObject.email);
      }

      this.applicationForm = false;
      this.otpVerificationScreen = false;
      this.accountExist = true;
      this.fetchData = await false;
    },
    async prepareUserRegistration() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.registerBtn.text = "Please wait...";
        this.registerBtn.disabled = true;
        const publicId = this.$route.params.public_id;
        let registerPostData = await {
          public_id: publicId,
          first_name: this.register.first_name,
          email: this.register.email,
          mobile: "91" + this.register.mobile,
        };

        let userRegistration = await this.registerUnapprovedUser(registerPostData);

        if (userRegistration.status) {
          this.$toast.success(`Successfully Registered, procceding to otp verification`, {
            position: "top",
            duration: 3000,
          });
          await localStorage.removeItem("publicUrlUser");
          await localStorage.setItem(
            "publicUrlUser",
            JSON.stringify({
              email: userRegistration.responseData.email,
              mobile: userRegistration.responseData.mobile,
              username: userRegistration.responseData.username,
            })
          );
          this.otpVerificationScreen = true;
          this.clearResponse();
        }
        if (!userRegistration.status) {
          this.$toast.error(`Something went wrong, please try again!`, {
            position: "top",
            duration: 3000,
          });
          this.clearResponse();
        }
      }
    },
    async registerUnapprovedUser(registerPostData) {
      let responseStatus = {
        status: null,
        responseData: null,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlineregpublicform/user/register`;
      await this.$axios
        .post(url, registerPostData)
        .then((response) => {
          responseStatus.status = true;
          responseStatus.responseData = response.data;
          response;
        })
        .catch((error) => {
          responseStatus.status = false;
          error;
        });
      return responseStatus;
    },
    async prepareOtpVerification() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.otpBtn.text = "Verifying...";
        this.otpBtn.disabled = true;
        let checkUserExist = await localStorage.getItem("publicUrlUser");
        let userDataObject = JSON.parse(checkUserExist);
        let otpPostData = {
          otp: this.otp,
          username: userDataObject.username,
        };

        let optVerification = await this.verifyOtp(otpPostData);
        if (optVerification.status) {
          this.$toast.success(`Otp verified successfully, proceeding to application`, {
            position: "top",
            duration: 3000,
          });
          await localStorage.setItem("token", optVerification.token);
          this.applicationForm = true;
          this.clearResponse(true);
        }
        if (!optVerification.status) {
          this.$toast.error(`Invalid OTP, please try again or request another OTP`, {
            position: "top",
            duration: 3000,
          });
          this.clearResponse(false);
        }
      }
    },
    async verifyOtp(otpPostData) {
      let responseStatus = {
        status: null,
        token: null,
      };

      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlineregpublicform/user/mobile/verify`;
      await this.$axios
        .post(url, otpPostData)
        .then((response) => {
          responseStatus.status = true;
          responseStatus.token = response.data.access_token;
          response;
        })
        .catch((error) => {
          responseStatus.status = false;

          error;
        });
      return responseStatus;
    },
    async resendOtp(status) {
      this.loadOtp = true;
      let checkUserExist = await localStorage.getItem("publicUrlUser");
      let userDataObject = JSON.parse(checkUserExist);
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlineregpublicform/user/mobile/resend`;
      await this.$axios
        .post(url, { username: userDataObject.username })
        .then((response) => {
          if (status) {
            this.requestOtpCountdown = true;
          }
          this.loadOtp = false;
          response;
        })
        .catch((error) => {
          this.loadOtp = false;
          error;
        });
    },
    clearResponse() {
      this.registerBtn.text = "Submit";
      this.otpBtn.text = "Verify OTP";
      setTimeout(() => {
        this.registerBtn.disabled = false;
        this.otpBtn.disabled = false;
      }, 3000);
    },
    logout() {
      localStorage.removeItem("publicUrlUser");
      localStorage.removeItem("token");
      this.register = {};
      this.accountExist = false;
      this.existingAccount = {};
    },
  },
};
</script>

<style >
.registerUnapprovedUser {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.registerUnapprovedUser .register {
  padding: 10px;
  box-shadow: 0 0 50px #ccc;
  width: 500px;
  height: 500px;
}

@media only screen and (max-width: 600px) {
  .registerUnapprovedUser {
    padding: 5px;
    min-height: 100vh;
  }

  .registerUnapprovedUser .register {
    width: 100%;
    height: auto;
  }
}
</style>
